import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from '@material-tailwind/react';
import AdminData from './AdminData';
import Foot from '../Footer/Foot';

function Admin() {
  const navigate = useNavigate();
  useEffect(() => {
    const auth = localStorage.getItem('AUTH');
    if (auth !== 'LOGGED_IN') navigate('/login');
  }, []);

  const [activeTab, setActiveTab] = React.useState('users');

  return (
    <div className="py-12 gradient">
      <Tabs
        value={activeTab}
        className="py-4  max-w-[1440px] mx-auto relative h-screen"
      >
        <TabsHeader
          className="bg-transparent rounded-none border-emerald-500-50"
          indicatorProps={{
            className: 'bg-transparent shadow-none rounded-none',
          }}
        >
          {AdminData.map(({ label, value }) => (
            <Tab
              key={value}
              value={value}
              onClick={() => setActiveTab(value)}
              className={
                activeTab === value
                  ? 'text-white text-xl relative'
                  : 'text-xl text-white opacity-30 relative'
              }
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody>
          {AdminData.map(({ value, desc }) => (
            <TabPanel key={value} value={value}>
              {desc}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
      <Foot className="fixed" />
    </div>
  );
}

export default Admin;
