import React from 'react';
import Navbar2 from '../Navbar/Navbar2';

import Foot from '../Footer/Foot';
import Contacts from '../Contacts/Contacts';

function ContactPage() {
  return (
    <div>
      <Navbar2 />
      <Contacts />
      <Foot />
    </div>
  );
}

export default ContactPage;
