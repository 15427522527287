import React from 'react';
import Login from '../Login/Login';
import Navbar2 from '../Navbar/Navbar2';

function LoginPage() {
  return (
    <div>
      <Navbar2 />
      <Login />
    </div>
  );
}

export default LoginPage;
