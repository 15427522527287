import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@material-tailwind/react';
import { BsFacebook } from 'react-icons/bs';
import { FaInstagramSquare, FaTwitterSquare } from 'react-icons/fa';

function Foot() {
  const currentYear = new Date().getFullYear();
  return (
    <div className="max-w-full mx-auto gradient">
      <div className="max-w-[1440px] mx-auto text-white bottom-0 center flex">
        <footer className="flex flex-row flex-wrap items-center justify-center w-full py-6 text-center border-t gap-y-6 gap-x-12 border-blue-gray-50 md:justify-between">
          <Typography color="blue-gray" className="font-normal">
            &copy; {currentYear} Hayahay Sunset
          </Typography>
          <ul className="flex flex-wrap items-center gap-y-2 gap-x-8">
            <li>
              <Link to="/about">
                <Typography
                  color="blue-gray"
                  className="font-normal transition-colors hover:text-emerald-500 focus:text-emerald-500"
                >
                  About Us
                </Typography>
              </Link>
            </li>
            <li>
              <Link to="/contacts">
                <Typography
                  href="#"
                  color="blue-gray"
                  className="font-normal transition-colors hover:text-emerald-500 focus:text-emerald-500"
                >
                  Contacts
                </Typography>
              </Link>
            </li>
            <li>
              <Typography
                as="a"
                href="https://www.facebook.com/"
                color="blue-gray"
                className="text-3xl font-normal transition-colors hover:text-emerald-500 focus:text-emerald-500"
              >
                <BsFacebook />
              </Typography>
            </li>
            <li>
              <Typography
                as="a"
                href="https://www.instagram.com/"
                color="blue-gray"
                className="text-3xl font-normal transition-colors hover:text-emerald-500 focus:text-emerald-500"
              >
                <FaInstagramSquare />
              </Typography>
            </li>
            <li>
              <Typography
                as="a"
                href="https://twitter.com/i/flow/login?redirect_after_login=%2F%3Flang%3Den"
                color="blue-gray"
                className="text-3xl font-normal transition-colors hover:text-emerald-500 focus:text-emerald-500"
              >
                <FaTwitterSquare />
              </Typography>
            </li>
          </ul>
        </footer>
      </div>
    </div>
  );
}

export default Foot;
