import React, { useState, useEffect } from 'react';
import { Card } from '@material-tailwind/react';
import { BiEdit } from 'react-icons/bi';
import { FcCancel } from 'react-icons/fc';
import BookingModal from '../Modal/BookingModal';

import constants from '../constants';
import axios from 'axios';

const TABLE_HEAD = [
  'Booking Id',
  'User Id',
  'Name',
  'Phone Number',
  'Email',
  'Tour',
  'Room',
  'Mode Of Payment',
  'Status',
];

function TableBookings() {
  const [bookingsData, setBookingsData] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedBookingData, setSelectedBookingData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(`${constants.ENDPOINT}/api/bookings`);
        const bookingsList = response.data.data;
        setBookingsData(bookingsList);
        console.log(bookingsData);
      } catch (error) {
        console.error(error);
      }
    };
    getData();
  }, []);

  const getIndexById = id =>
    bookingsData.findIndex(bookingData => bookingData.id === id);

  const deleteBooking = id => {
    const confirmDelete = window.confirm(
      'Are you sure you want to CANCEL this booking?'
    );
    if (confirmDelete) {
      fetch(`${constants.ENDPOINT}/api/bookings/${id}`, {
        method: 'DELETE',
        mode: 'cors',
      })
        .then(response => response.json())
        .then(data => {
          if (data.success === true && data.data.recordsAffected === 1) {
            const newBookingsData = [...bookingsData];
            newBookingsData.splice(getIndexById(id), 1);
            setBookingsData(newBookingsData);
          } else {
            alert(`Unable to delete users data id=${id}`);
          }
        })
        .catch(error => console.error(error));
    }
  };

  const openModal = bookingData => {
    setSelectedBookingData(bookingData);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const updateBooking = updatedBookingData => {
    fetch(`${constants.ENDPOINT}/api/bookings/${selectedBookingData.id}`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedBookingData),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          const updatedBookingsData = bookingsData.map(booking => {
            if (booking.id === selectedBookingData.id) {
              return {
                ...booking,
                ...updatedBookingData,
              };
            }
            return booking;
          });
          setBookingsData(updatedBookingsData);
        } else {
          alert('There was an issue updating this booking.');
        }
      })
      .catch(error => console.error(error));
  };

  return (
    <>
      <Card className="w-full mt-4 overflow-scroll border-t">
        <table className="w-full text-left table-auto min-w-max">
          <thead>
            <tr>
              {TABLE_HEAD.map(head => (
                <th
                  key={head}
                  className="p-4 border-b border-blue-gray-100 bg-blue-gray-50"
                >
                  <h2 className="font-normal leading-none opacity-70">
                    {head}
                  </h2>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {bookingsData.map(
              (
                {
                  id,
                  userId,
                  name,
                  phoneNumber,
                  email,
                  tour,
                  room,
                  modeOfPayment,
                  status,
                },
                index
              ) => {
                const isLast = index === bookingsData.length - 1;
                const classes = isLast
                  ? 'p-4 border-b'
                  : 'p-4 border-b border-blue-gray-50';

                return (
                  <tr key={id}>
                    <td className={classes}>
                      <h3 className="font-normal">{id}</h3>
                    </td>
                    <td className={classes}>
                      <h3 className="font-normal">{userId}</h3>
                    </td>
                    <td className={`${classes} bg-blue-gray-50/50`}>
                      <h3 className="font-normal">{name}</h3>
                    </td>
                    <td className={classes}>
                      <h3 className="font-normal">{phoneNumber}</h3>
                    </td>
                    <td className={classes}>
                      <h3 className="font-normal">{email}</h3>
                    </td>
                    <td className={classes}>
                      <h3 className="font-normal">{tour}</h3>
                    </td>
                    <td className={classes}>
                      <h3 className="font-normal">{room}</h3>
                    </td>
                    <td className={`${classes} border-t bg-blue-gray-50/50`}>
                      <h3 className="font-normal">{modeOfPayment}</h3>
                    </td>
                    <td className={`${classes} border-t bg-blue-gray-50/50`}>
                      <h3 id="statusContainer" className="font-normal ">
                        {status}
                      </h3>
                    </td>
                    <td className={`${classes} border-t bg-blue-gray-50/50`}>
                      <h3
                        onClick={() =>
                          openModal({
                            id,
                            name,
                            email,
                            status,
                          })
                        }
                        className="text-2xl font-normal text-blue-600 cursor-pointer hover:scale-125"
                      >
                        <BiEdit />
                      </h3>
                    </td>
                    <td className={`${classes} border-t bg-blue-gray-50/50`}>
                      <h3
                        onClick={() => deleteBooking(id)}
                        className="text-2xl font-normal cursor-pointer hover:scale-125"
                      >
                        <FcCancel />
                      </h3>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </Card>
      <BookingModal
        isOpen={isModalOpen}
        onClose={closeModal}
        selectedBookingData={selectedBookingData}
        updateBooking={updateBooking}
      />
    </>
  );
}

export default TableBookings;
