import React from 'react';
import Navbar from '../Navbar/Navbar';
import Landing from '../Landing/Landing';
import Hero from '../Landing/Hero';
import Foot from '../Footer/Foot';

function LandingPage() {
  return (
    <div>
      <Navbar />
      <Hero />
      {/* <Landing /> */}
      <Foot />
    </div>
  );
}

export default LandingPage;
