import { useState } from 'react';
import axios from 'axios';
import constants from '../constants';
import { useNavigate } from 'react-router-dom';

import { Card, Input, Button, Typography } from '@material-tailwind/react';

function Signup() {
  const [register, setRegister] = useState({
    name: '',
    address: '',
    username: '',
    password: '',
    email: '',
  });

  const navigate = useNavigate();

  function submit(e) {
    e.preventDefault();
    console.log('submit button click');
    axios
      .post(`${constants.ENDPOINT}/api/users`, {
        name: register.name,
        address: register.address,
        username: register.username,
        password: register.password,
        email: register.email,
      })
      .then(res => {
        console.log(res.data);
        regSuccessful();
      })
      .catch(error => {
        console.error('Error occurred while submitting the form:', error);
      });
  }

  function handleReg(e) {
    const newRegister = { ...register };
    newRegister[e.target.id] = e.target.value;
    setRegister(newRegister);
    console.log(newRegister);
  }

  function regSuccessful() {
    window.alert('Registration completed successfully!');
    navigateToLogin();
  }

  function navigateToLogin() {
    navigate('/login');
  }

  return (
    <div className="flex justify-center max-w-full py-16 mx-auto gradient">
      <Card color="white" shadow={false} className="p-8 border">
        <Typography variant="h4" color="blue-gray">
          Sign Up
        </Typography>
        <Typography color="gray" className="mt-1 font-normal">
          Enter your details to register.
        </Typography>
        <form
          onSubmit={submit}
          className="max-w-screen-lg mt-8 mb-2 w-80 sm:w-96"
        >
          <div className="flex flex-col gap-6 mb-4">
            <Input
              onChange={handleReg}
              name="name"
              id="name"
              value={register.name}
              size="lg"
              label="Name"
              className="my-2"
            />
            <Input
              onChange={handleReg}
              name="address"
              id="address"
              value={register.address}
              size="lg"
              label="Address"
              className="my-2"
            />
            <Input
              onChange={handleReg}
              name="username"
              id="username"
              value={register.username}
              size="lg"
              label="Username"
              className="my-2"
            />
            <Input
              onChange={handleReg}
              name="password"
              id="password"
              value={register.password}
              type="password"
              size="lg"
              label="Password"
              className="my-2"
            />
            <Input
              onChange={handleReg}
              name="email"
              id="email"
              value={register.email}
              size="lg"
              label="Email"
              className="my-2"
            />
          </div>

          <Button type="submit" className="mt-8" fullWidth>
            Register
          </Button>
        </form>
      </Card>
    </div>
  );
}

export default Signup;
