import React, { useState } from 'react';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { Card, Typography, Button } from '@material-tailwind/react';
import axios from 'axios';
import constants from '../constants';

function AddTourModal({ isOpen, onClose, addTour }) {
  const [addTourData, setAddTourData] = useState({
    name: '',
    rooms: '',
    price: '',
    description: '',
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setAddTourData({ ...addTourData, [name]: value });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const confirmed = window.confirm('Are you sure you want to add the tour?');
    if (!confirmed) {
      onClose();
      return;
    }
    try {
      const response = await axios.post(
        `${constants.ENDPOINT}/api/tours`,
        addTourData
      );
      const data = response.data;
      if (data.success) {
        addTour(data.data);
        onClose();
      } else {
        alert('There was an issue adding the tour.');
      }
    } catch (error) {
      console.error('Error adding tour:', error);
      alert('Error adding the tour.');
    }
  };

  if (!isOpen) return null;
  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full">
      <div className="bg-green-500 rounded-lg">
        <div className="flex flex-row-reverse mt-2">
          <button
            onClick={onClose}
            className="mr-2 text-5xl text-white hover:scale-125"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        <Card color="green" shadow={false} className="md:w-[730px]">
          <Typography variant="h4" color="blue-gray" className="text-center">
            Add Tour
          </Typography>

          <form
            onSubmit={handleSubmit}
            className="w-full max-w-screen-lg mt-8 mb-2"
          >
            <div className="items-center mb-4 text-center md:grid md:grid-cols-3 md:w-full g-6">
              <label htmlFor="name">
                <p>Name</p>
                <input
                  type="text"
                  name="name"
                  value={addTourData.name}
                  onChange={handleChange}
                  className="text-black rounded-lg"
                />
              </label>
              <label htmlFor="rooms">
                <p>Rooms</p>
                <input
                  type="text"
                  name="rooms"
                  value={addTourData.rooms}
                  onChange={handleChange}
                  className="text-black rounded-lg"
                />
              </label>
              <label htmlFor="status">
                <p>Price</p>
                <input
                  type="text"
                  name="price"
                  value={addTourData.price}
                  onChange={handleChange}
                  className="text-black rounded-lg"
                />
              </label>
            </div>
            <label
              htmlFor="status"
              className="flex flex-col mx-12 mb-8 text-center"
            >
              <p>Description</p>
              <textarea
                name="description"
                id="description"
                cols="30"
                rows="5"
                value={addTourData.description}
                onChange={handleChange}
                className="flex items-center justify-center mt-4 text-black rounded-lg"
              ></textarea>
            </label>
            <div className="flex justify-center gap-20 mb-4 mr-2 md:gap-2">
              <Button type="submit" className="w-56">
                Submit
              </Button>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
}

export default AddTourModal;
