import React from 'react';
import Navbar2 from '../Navbar/Navbar2';
import Signup from '../Login/Signup';
import Foot from '../Footer/Foot';

function SignupPage() {
  return (
    <div>
      <Navbar2 />
      <Signup />
      <Foot />
    </div>
  );
}

export default SignupPage;
