import React from 'react';
import Navbar2 from '../Navbar/Navbar2';
import Tour from '../TourPackage/Tour';
import Foot from '../Footer/Foot';

function HomePage() {
  return (
    <div>
      <Navbar2 />
      <Tour />
      <Foot />
    </div>
  );
}

export default HomePage;
