import React from 'react';
import { Link } from 'react-router-dom';
import backGround from '../../components/img/video-2.mp4';

import Carousel from '../Carousel/Carousel';
import { FaLocationArrow } from 'react-icons/fa';

function Hero() {
  return (
    <div className="relative h-screen max-w-full">
      <video
        autoPlay
        loop
        muted
        className="object-cover w-full h-full"
        src={backGround}
      />
      <section className="absolute inset-0 lg:z-50 lg:top-[20%] lg:left-[8%]">
        <div className="container flex flex-col items-center px-5 py-24 mx-auto md:flex-row">
          <div className="w-5/6 mb-10 lg:max-w-lg lg:w-full md:w-1/2 md:mb-0">
            <Carousel />
          </div>
          <div className="flex flex-col items-center text-center lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 md:items-start md:text-left">
            <h1 className="mb-4 text-5xl font-bold text-orange-400 lg:text-8xl title-font">
              Welcome
              {/* 
              <span className="text-2xl text-white">
                "Sun, sea, and serenity await you "
              </span> */}
            </h1>

            <p className="mb-8 text-xl italic leading-relaxed text-white">
              Find your slice of paradise at Hayahay Sunset, where worries wash{' '}
              <br className="hidden lg:inline-block" />
              away with the tide and laughter fills the air.
            </p>
            <div className="z-50 flex justify-center">
              <Link
                to="/tour"
                className="flex gap-4 px-6 py-2 text-lg text-white duration-500 border-2 rounded-lg hover:scale-125 hover:bg-emerald-500 "
              >
                Get Started
                <FaLocationArrow className="text-2xl text-orange-400 h" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <div className="absolute top-0 left-0 w-full h-full bg-gray-900/20"></div>
    </div>
  );
}

export default Hero;
