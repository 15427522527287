import React, { useState, useEffect } from 'react';
import axios from 'axios';
import constants from '../constants';
import { Card, Input, Typography, Button } from '@material-tailwind/react';

function BookingForm({ tour, onClose }) {
  const initialBooking = {
    userId: '',
    name: '',
    phoneNumber: '',
    email: '',
    tour: '',
    room: '',
    dateStart: '',
    dateEnd: '',
    price: '',
  };
  const [bookTour, setBookTour] = useState(initialBooking);

  useEffect(() => {
    console.log('Tour  prop:', tour);
    if (tour && tour.name && tour.rooms && tour.price) {
      setBookTour(prevData => ({
        ...prevData,
        tour: tour.name,
        room: tour.rooms,
        price: tour.price,
      }));
    }
  }, [tour]);

  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem('USER'));

    if (localData && localData.name && localData.email && localData.id) {
      setBookTour(prevData => ({
        ...prevData,
        name: localData.name,
        email: localData.email,
        userId: localData.id,
      }));
    }
  }, []);

  function submitBooking(e) {
    e.preventDefault();

    const confirmed = window.confirm(
      'Are you sure you want to book this tour?'
    );
    if (!confirmed) {
      onClose();
      return;
    }

    axios
      .post(`${constants.ENDPOINT}/api/bookings`, bookTour)

      .then(res => {
        console.log(res.data);
        alert('Booking successful!');
        onClose();
      })
      .catch(error => {
        console.error('Error submitting booking:', error);
      });
  }

  function handleBooking(e) {
    const newBookTour = { ...bookTour };
    newBookTour[e.target.id] = e.target.value;
    setBookTour(newBookTour);
    console.log(newBookTour);
  }

  return (
    <div className="flex items-center justify-center lg:w-full ">
      <Card
        color="transparent"
        shadow={false}
        className="p-2 px-8 mt-0 bg-white"
      >
        <Typography variant="h4" color="blue-gray">
          Booking Form
        </Typography>

        <form
          onSubmit={submitBooking}
          className="max-w-screen-lg grid-cols-2 mt-4 mb-2 w-80 sm:w-96"
        >
          <div className="flex flex-col gap-6 mb-8">
            <Input
              onChange={handleBooking}
              hidden
              disabled
              size="lg"
              label="UserId"
              name="userId"
              id="userId"
              value={bookTour.userId}
              className="my-1"
            />
            <Input
              onChange={handleBooking}
              disabled
              size="lg"
              label="Name"
              name="name"
              id="name"
              value={bookTour.name}
              className="my-1"
            />
            <Input
              onChange={handleBooking}
              disabled
              size="lg"
              label="Email"
              name="email"
              id="email"
              value={bookTour.email}
              className="my-1"
            />
            <Input
              onChange={handleBooking}
              size="lg"
              label="Phone Number"
              name="phoneNumber"
              id="phoneNumber"
              value={bookTour.phoneNumber}
              className="my-1"
            />
          </div>
          <div className="grid grid-cols-2 gap-6 mb-8">
            <Input
              onChange={handleBooking}
              disabled
              size="lg"
              label="Tour Package"
              name="tour"
              id="tour"
              value={bookTour.tour}
              className=""
            />
            <Input
              onChange={handleBooking}
              disabled
              size="lg"
              label="Room"
              name="rooms"
              id="rooms"
              value={bookTour.room}
              className=""
            />
          </div>
          <div className="grid grid-cols-2 gap-6 mb-8">
            <Input
              onChange={handleBooking}
              type="date"
              size="lg"
              label="From"
              name="dateStart"
              id="dateStart"
              value={bookTour.dateStart}
              className=""
            />
            <Input
              onChange={handleBooking}
              type="date"
              size="lg"
              label="Until"
              name="dateEnd"
              id="dateEnd"
              value={bookTour.dateEnd}
              className=""
            />
          </div>
          <div className="flex flex-col gap-6 mb-4 ">
            <Input
              onChange={handleBooking}
              disabled
              size="lg"
              label="Price"
              name="price"
              id="price"
              value={bookTour.price}
              className="mb-1"
            />
          </div>
          <div className="flex justify-center gap-4">
            <Button
              onClick={onClose}
              color="green"
              className="w-full mt-4 text-red-600"
            >
              Cancel
            </Button>
            <Button type="submit" color="green" className="w-full mt-4">
              Submit
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default BookingForm;
