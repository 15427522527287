import { BrowserRouter, Route, Routes } from 'react-router-dom';

import HomePage from './components/pages/HomePage';

import LandingPage from './components/pages/LandingPage';
import LoginPage from './components/pages/LoginPage';
import SignupPage from './components/pages/SignupPage';

import PageNotFound from './components/pages/PageNotFound';
import Admin from './components/Users/Admin';
import ContactPage from './components/pages/ContactPage';

import About from './components/About/About';

// Pages
// import RootLayout from './components/Layout/RootLayout';
// import Landing from './components/Landing/Landing';
// import Tour from './components/TourPackage/Tour';
// import ErrorView from './components/ErrorView/ErrorView';
// import Room from './components/Rooms/Room';
// import Admin from './components/Users/Admin';
// import Login from './components/Login/Login';
// import Booking from './components/Booking/Booking';

// Routing
// const router = createBrowserRouter(
//   createRoutesFromElements(
//     <Route>
//       <Route index element={<Landing />} />
//       <Route path="home" element={<Tour />} />
//       <Route path="room" element={<Room />} />
//       <Route path="*" element={<ErrorView />} />
//       <Route path="login" element={<Login />} />
//       <Route path="register" element={<Signup />} />
//       <Route path="admin" element={<Admin />} />
//     </Route>
//   )
// );

function App() {
  // useEffect(() => {
  //   const auth = localStorage.getItem('AUTH');
  //   setLoggedIn(auth === 'LOGGED_IN');
  // }, []);

  return (
    //<RouterProvider router={router} />;
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="tour" element={<HomePage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<SignupPage />} />
        <Route path="about" element={<About />} />
        <Route path="contacts" element={<ContactPage />} />
        <Route path="admin" element={<Admin />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
