import React, { useState, useEffect } from 'react';
import { Card, Typography } from '@material-tailwind/react';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import constants from '../constants';
import axios from 'axios';

const TABLE_HEAD = [
  '',
  'ID',
  'Username',
  'Name',
  'Address',
  'Email',
  'User-Type',
];

function UsersTable() {
  const [usersData, setUsersData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(`${constants.ENDPOINT}/api/users`);
        const usersList = response.data.data;
        setUsersData(usersList);
        // console.log(usersList);
      } catch (error) {
        console.error(error);
      }
    };
    getData();
  }, []);
  const getIndexById = id =>
    usersData.indexOf(usersData.filter(userData => userData.id === id)[0]);

  const deleteUser = id => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this user?'
    );
    if (confirmDelete) {
      fetch(`${constants.ENDPOINT}/api/users/${id}`, {
        method: 'DELETE',
        mode: 'cors',
      })
        .then(response => response.json())
        .then(data => {
          if (data.success === true && data.data.recordsAffected === 1) {
            const newUsersData = [...usersData];
            newUsersData.splice(getIndexById(id), 1);
            setUsersData(newUsersData);
          } else {
            alert(`Unable to delete users data id=${id}`);
          }
        })
        .catch(error => console.error(error));
    }
  };

  return (
    <>
      <Card className="w-full mt-4 overflow-scroll border-t">
        <table className="w-full text-left table-auto min-w-max">
          <thead>
            <tr>
              {TABLE_HEAD.map(head => (
                <th
                  key={head}
                  className="p-4 border-b border-blue-gray-100 bg-blue-gray-50"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {usersData.map(
              (
                { id, username, name, address, email, userType, avatar },
                index
              ) => {
                const isLast = index === usersData.length - 1;
                const classes = isLast
                  ? 'p-4 border-b'
                  : 'p-4 border-b border-blue-gray-50';

                return (
                  <tr key={index}>
                    <td className={classes}>
                      <img
                        src={`${constants.ENDPOINT}/${avatar}`}
                        className="object-cover border-2 border-gray-300 rounded-full w-14 h-14"
                        alt="Avatar"
                      />

                      {/*{`${constants.ENDPOINT}/${usersData.avatar}`}*/}
                    </td>
                    <td className={classes}>
                      <h3 className="font-normal">{id}</h3>
                    </td>
                    <td className={`${classes} bg-blue-gray-50/50`}>
                      <h3 className="font-normal">{username}</h3>
                    </td>
                    <td className={classes}>
                      <h3 className="font-normal">{name}</h3>
                    </td>
                    <td className={classes}>
                      <h3 className="font-normal">{address}</h3>
                    </td>
                    <td className={classes}>
                      <h3 className="font-normal">{email}</h3>
                    </td>
                    <td className={classes}>
                      <h3 className="font-normal">{userType}</h3>
                    </td>

                    <td className={`${classes} border-t bg-blue-gray-50/50`}>
                      <h3
                        onClick={() => deleteUser(id)}
                        className="text-2xl font-normal text-red-600 cursor-pointer hover:scale-125"
                      >
                        <RiDeleteBin2Fill />
                      </h3>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </Card>
    </>
  );
}

export default UsersTable;
