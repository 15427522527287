import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TourItems from './TourItems';

import constants from '../constants';

function Tour() {
  const [tours, setTours] = useState([]);

  // get api
  useEffect(() => {
    async function getData() {
      try {
        const response = await axios.get(`${constants.ENDPOINT}/api/tours`);

        const data = response.data.data;
        setTours(data);
      } catch (error) {
        console.log(error);
      }
    }

    getData();
  }, []);

  return (
    <div className="max-w-[1440px] mx-auto grid lg:grid-cols-6 gap-4 py-8">
      {tours.map(tour => (
        <TourItems
          key={tour.id}
          tour={tour}
          // name={list.name}
          // description={list.description}
          // img={list.img}
        />
      ))}
    </div>
  );
}

export default Tour;
