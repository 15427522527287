import React, { useState, useEffect } from 'react';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { Card, Typography, Button } from '@material-tailwind/react';

function TourModal({ isOpen, onClose, selectedTourData, updateTour }) {
  const [updateStatus, setUpdateStatus] = useState({
    // name: selectedBookingData ? selectedBookingData.name : '',
    // email: selectedBookingData ? selectedBookingData.email : '',
    // status: selectedBookingData ? selectedBookingData.status : '',
    name: '',
    rooms: '',
    price: '',
    description: '',
  });
  useEffect(() => {
    if (selectedTourData) {
      setUpdateStatus({
        name: selectedTourData.name,
        rooms: selectedTourData.rooms,
        price: selectedTourData.price,
        description: selectedTourData.description,
      });
    }
  }, [selectedTourData]);

  function submitStatus(e) {
    e.preventDefault();
    updateTour(updateStatus);
    onClose();
  }

  function handleStatus(e) {
    const { name, value } = e.target;
    setUpdateStatus(prevStatus => ({
      ...prevStatus,
      [name]: value,
    }));
  }

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full">
      <div className="bg-green-500 rounded-lg">
        <div className="flex flex-row-reverse mt-2">
          <button
            onClick={onClose}
            className="mr-2 text-5xl text-white hover:scale-125"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        <Card color="green" shadow={false} className="md:w-[730px]">
          <Typography variant="h4" color="blue-gray" className="text-center">
            Edit Tour
          </Typography>

          <form
            onSubmit={e => submitStatus(e)}
            className="w-full max-w-screen-lg mt-8 mb-2"
          >
            <div className="items-center mb-4 text-center md:grid md:grid-cols-3 md:w-full g-6">
              <label htmlFor="name" className="col-span-">
                <p>Name </p>
                <input
                  type="text"
                  name="name"
                  value={updateStatus.name}
                  onChange={e => handleStatus(e)}
                  className="text-black rounded-lg"
                />
              </label>
              <label htmlFor="rooms">
                <p>Rooms</p>
                <input
                  type="text"
                  name="rooms"
                  value={updateStatus.rooms}
                  onChange={e => handleStatus(e)}
                  className="text-black rounded-lg"
                />
              </label>
              <label htmlFor="status">
                <p>Price</p>
                <input
                  type="text"
                  name="price"
                  value={updateStatus.price}
                  onChange={e => handleStatus(e)}
                  className="text-black rounded-lg"
                />
              </label>
            </div>
            <label
              htmlFor="status"
              className="flex flex-col mx-12 mb-8 text-center"
            >
              <p>Description</p>
              <textarea
                type="text"
                name="description"
                id="description"
                cols="30"
                rows="5"
                value={updateStatus.description}
                onChange={e => handleStatus(e)}
                className="flex items-center justify-center mt-4 text-black rounded-lg "
              ></textarea>
            </label>

            <div className="flex justify-center gap-20 mb-4 mr-2 md:gap-2">
              <Button type="submit" className="w-56">
                Submit
              </Button>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
}

export default TourModal;
