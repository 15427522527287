import React, { useState, useEffect } from 'react';
import { Button, Card, Typography, IconButton } from '@material-tailwind/react';

import { BiEdit } from 'react-icons/bi';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { FcUpload } from 'react-icons/fc';

import TourModal from '../Modal/TourModal';
import AddTourModal from '../Modal/AddTourModal';
import axios from 'axios';
import constants from '../constants';

const TABLE_HEAD = [
  'Tour Package',
  'Room Type',
  'Price',
  'Description',
  'Edit | Delete',
  'Upload Image',
];

function TableTour() {
  const [toursData, setToursData] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedTourData, setSelectedTourData] = useState(null);

  const [isNewModalOpen, setNewModalOpen] = useState(false);

  useEffect(() => {
    const getTour = async () => {
      try {
        const response = await axios.get(`${constants.ENDPOINT}/api/tours`);
        setToursData(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.error('Error fetching data');
      }
    };
    getTour();
  }, []);

  const getIndexById = id =>
    toursData.findIndex(tourData => tourData.id === id);

  const delTour = async id => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this Tour?'
    );
    if (confirmDelete) {
      try {
        const response = await axios.delete(
          `${constants.ENDPOINT}/api/tours/${id}`
        );
        const data = response.data;
        if (data.success === true && data.data.recordsAffected === 1) {
          const newToursData = [...toursData];
          newToursData.splice(getIndexById(id), 1);
          setToursData(newToursData);
        } else {
          alert(`Unable to delete tour id=${id}`);
        }
      } catch (error) {
        console.error('Error deleting tour:', error);
        alert(`Error deleting tour id=${id}`);
      }
    }
  };

  const openModal = tourData => {
    setSelectedTourData(tourData);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const updateTour = updatedTourData => {
    const confirmUpdate = window.confirm(
      'Are you sure you want to Update this Tour?'
    );
    if (confirmUpdate) {
      axios
        .put(
          `${constants.ENDPOINT}/api/tours/${selectedTourData.id}`,
          updatedTourData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(response => {
          const data = response.data;
          if (data.success) {
            const updatedToursData = toursData.map(tour => {
              if (tour.id === selectedTourData.id) {
                return {
                  ...tour,
                  ...updatedTourData,
                };
              }
              return tour;
            });
            setToursData(updatedToursData);
          } else {
            alert('There was an issue updating this booking.');
          }
        })
        .catch(error => console.error(error));
    }
  };
  const [uploadImage, setUploadImage] = useState('');

  function handleImageChange(e) {
    console.log(e.target.files);
    setUploadImage(e.target.files[0]);
  }

  function handleUpload(e, id) {
    e.preventDefault();
    const isConfirmed = window.confirm(
      'Are you sure you want to upload the image?'
    );
    if (!isConfirmed) {
      return;
    }
    const formData = new FormData();
    formData.append('img', uploadImage);
    axios
      .post(`${constants.ENDPOINT}/api/tours/${id}/img`, formData)
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.error(err);
      });
  }

  const openNewModal = tourData => {
    setNewModalOpen(true);
  };

  const closeNewModal = () => {
    setNewModalOpen(false);
  };

  const addNewTour = newTourData => {
    setToursData([...toursData, newTourData]);
  };

  return (
    <>
      <Card className="w-full h-full mt-4 overflow-scroll ">
        <table className="w-full p-4 text-left table-auto min-w-max">
          <thead>
            <tr>
              {TABLE_HEAD.map(head => (
                <th
                  key={head}
                  className="p-4 border-t border-b border-blue-gray-100 bg-blue-gray-50"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {toursData.map(({ id, name, rooms, price, description }, index) => {
              const isLast = index === toursData.length - 1;
              const classes = isLast
                ? 'p-4 border-b'
                : 'p-4 border-b border-blue-gray-50';

              return (
                <tr key={id}>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {name}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {rooms}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {price}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {description}
                    </Typography>
                  </td>
                  <td className="w-32 pl-4 border-b">
                    <div className="flex gap-5 ">
                      <IconButton
                        size="sm"
                        variant="outlined"
                        color="blue"
                        className="border-0"
                      >
                        <BiEdit
                          onClick={() =>
                            openModal({
                              id,
                              name,
                              rooms,
                              price,
                              description,
                            })
                          }
                          className="text-3xl text-blue-600 cursor-pointer hover:scale-125"
                        />
                      </IconButton>

                      <IconButton
                        size="sm"
                        variant="outlined"
                        color="blue"
                        className="border-0"
                      >
                        <RiDeleteBin2Fill
                          onClick={() => delTour(id)}
                          className="text-3xl text-red-600 cursor-pointer hover:scale-125"
                        />
                      </IconButton>
                    </div>
                  </td>
                  <td className={classes}>
                    <form onSubmit={e => handleUpload(e, id)}>
                      <input
                        onChange={handleImageChange}
                        name="file"
                        id="img"
                        type="file"
                        className="w-64 mr-4 border rounded-lg"
                      />
                      <IconButton
                        type="submit"
                        size="sm"
                        variant="outlined"
                        color="blue"
                        className="border-0"
                      >
                        <FcUpload className="text-3xl text-red-600 cursor-pointer hover:scale-125" />
                      </IconButton>
                    </form>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="flex gap-4 p-4">
          <Button size="sm" onClick={() => openNewModal(true)}>
            Add tour
          </Button>
        </div>
      </Card>
      <TourModal
        isOpen={isModalOpen}
        onClose={closeModal}
        selectedTourData={selectedTourData}
        updateTour={updateTour}
      />

      <AddTourModal
        isOpen={isNewModalOpen}
        onClose={closeNewModal}
        addTour={addNewTour}
      />
    </>
  );
}

export default TableTour;
