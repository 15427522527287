import React, { useState } from 'react';
import constants from '../constants';
import BookingForm from '../Booking/Booking';
import { AiOutlineCloseCircle } from 'react-icons/ai';

import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
  PopoverContent,
  Popover,
  PopoverHandler,
} from '@material-tailwind/react';

// Modal component
const Booking = ({ tour, onClose }) => {
  return (
    <div className="z-50 grid w-[520px] h-[700px] gradient p-8 pb-4 rounded-lg">
      <BookingForm
        name={tour.name}
        price={tour.price}
        rooms={tour.rooms}
        tour={tour}
        onClose={onClose}
      />
    </div>
  );
};

const TourItems = ({ tour }) => {
  const [showModal, setShowModal] = useState(false);
  const auth = localStorage.getItem('AUTH');

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  if (!tour) return null;

  return (
    <>
      <Card className="flex-row w-full max-w-[48rem] lg:col-span-2 ">
        <CardHeader
          shadow={false}
          floated={false}
          className="w-2/5 m-0 rounded-r-none shrink-0"
        >
          <img
            src={`${constants.ENDPOINT}/${tour.img}`}
            alt="img"
            className="object-fill w-full h-full transition-all duration-700 transform scale-100 hover:scale-125"
          />
        </CardHeader>

        <CardBody className="w-full rounded-r-xl bg-emerald-400">
          <Typography variant="h4" color="blue-gray" className="mb-4 text-5xl">
            {tour.name}
          </Typography>
          <Typography color="gray" className="mb-2">
            Room:
          </Typography>
          <Typography color="gray" className="mb-8 text-4xl">
            {tour.rooms}
          </Typography>
          <Typography color="gray" className="mb-2 font-normal">
            Price:
          </Typography>
          <Typography color="gray" className="mb-8 text-4xl">
            &#8369; {tour.price}
          </Typography>
          <div className="flex justify-between gap-8 w-max">
            {auth === 'LOGGED_IN' ? (
              <Button size="sm" onClick={openModal}>
                Book Now
              </Button>
            ) : null}

            <Popover
              animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0, y: 25 },
              }}
            >
              <PopoverHandler>
                <Button>Details</Button>
              </PopoverHandler>
              <PopoverContent>{tour.description}</PopoverContent>
            </Popover>
          </div>
        </CardBody>
      </Card>

      {/* Modal */}
      {showModal && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
          <Booking tour={tour} onClose={closeModal} />
        </div>
      )}
    </>
  );
};

export default TourItems;
export { Booking };
