import TableBookings from '../Tables/TableBookings';
import TableTour from '../Tables/TableTour';
import UserTable from '../Tables/UsersTable';

const AdminData = [
  {
    label: 'Users',
    value: 'users',
    desc: <UserTable />,
  },
  {
    label: 'Bookings',
    value: 'bookings',
    desc: <TableBookings />,
  },

  {
    label: 'Tour',
    value: 'tour',
    desc: <TableTour />,
  },
  // {
  //   label: 'Svelte',
  //   value: 'svelte',
  //   desc: `We're not always in the position that we want to be at.
  //     We're constantly growing. We're constantly making mistakes. We're
  //     constantly trying to express ourselves and actualize our dreams.`,
  // },
];

export default AdminData;
