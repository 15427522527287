import React from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  Button,
  Typography,
} from '@material-tailwind/react';
import { FcConferenceCall, FcCallback, FcHome } from 'react-icons/fc';

const contactDetails = [
  {
    logo: <FcConferenceCall />,
    name: 'Talk to a member of our Team',
    description:
      "We'll help you find the right destination and pricing for your dream vacation",
    btn: 'FB messenger',
  },
  {
    logo: <FcCallback />,
    name: '24/7 Support',
    description:
      'From the moment you start planning until you return home, our team will be available round-the-clock to assist you with any queries or concerns.',
    btn: '+63-995-6790-5522',
  },
  {
    logo: <FcHome />,
    name: 'We are located at',
    description: '',
    street: 'B9 L2 Kalye Maligaya',
    bgy: 'Tanauan, Tanza',
    prov: 'Cavite',
    btn: 'Address',
  },
];

function Contacts() {
  return (
    <div className="h-screen max-w-full pt-12 gradient">
      <div className="py-12 text-4xl font-bold text-center text-white">
        <h1>Contact Us</h1>
      </div>
      <div className="flex flex-col lg:flex-row items-center justify-evenly lg:w-[1440px] mx-auto pb-8">
        {contactDetails.map(
          ({ logo, name, description, btn, bgy, street, prov }, index) => (
            <Card key={index} className="mt-6 w-96">
              <CardBody className="h-[300px]">
                <Typography
                  variant="h5"
                  color="blue-gray"
                  className="mb-2 text-8xl"
                >
                  {logo}
                </Typography>
                <Typography variant="h5" color="blue-gray" className="mb-2">
                  {name}
                </Typography>
                <Typography variant="h5" color="blue-gray" className="mb-2">
                  {description}
                </Typography>
                <Typography variant="h5" color="blue-gray" className="mb-2">
                  {street}
                </Typography>
                <Typography variant="h5" color="blue-gray" className="mb-2">
                  {bgy}
                </Typography>
                <Typography variant="h5" color="blue-gray" className="mb-2">
                  {prov}
                </Typography>
              </CardBody>
              <CardFooter className="pt-0">
                <a href="/" className="inline-block">
                  <Button
                    color="green"
                    size="sm"
                    variant="text"
                    className="flex items-center gap-2 text-lg"
                  >
                    {btn}
                  </Button>
                </a>
              </CardFooter>
            </Card>
          )
        )}
      </div>
    </div>
  );
}

export default Contacts;
